import React from "react";
import ReactDOM from "react-dom";
import Header from './components/Header'
import Sent from './components/Sent'

class HelloMessage extends React.Component {
    render() {
        return <div>
            <Header/>
            <Sent/>
        </div>
    }
}

let App = document.getElementById("app");

ReactDOM.render(<HelloMessage name="Shawn" />, App);