import React, { Component } from 'react';

class Tweet extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
        };
    }

    componentDidMount() {

    }

    render() {
        const SECONDSINWEEK = 604800
        const MIN = 0.35
        let ttl = this.props.expires_at - (Date.now().valueOf()/1000) 
        let opacity = (ttl / SECONDSINWEEK) + MIN
        return <div>
            <li key={this.props.id_str}>
                <p className="is-family-sans-serif" style={{ opacity: opacity, visibility: 'visible'}}>
                    {this.props.text} <a target="_blank" href={'https://twitter.com/realDonaldTrump/status/' + this.props.id_str}><span className="tooltip" data-tooltip={this.props.sentscore}>{this.props.sentiment}</span></a>
                </p>
            </li>
        </div>
    }
}


export default Tweet;