import React, { Component } from 'react';
import Tweet from './Tweet'
const titleize = require('titleize');


class Sent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
        };
    }

    componentDidMount() {
        fetch('https://38vjd6ge00.execute-api.us-east-1.amazonaws.com/dev/').then(response => response.json())
        .then(data => this.setState({ data })).then(data =>{
            let neg = 0
            let neu = 0
            let pos = 0
            let samples = this.state.data.Items.length
            this.state.data.Items.forEach(e => {
                switch(e.sentiment){
                    case 'NEUTRAL':
                        neu++
                        break;
                    case 'POSITIVE':
                        pos++
                        break;
                    case 'NEGATIVE':
                        neg++
                        break;
                }    
            })

            let moods = { '🙂': pos, '😐': neu, '☹️': neg }
            let mood_max = Math.max(...Object.values(moods))
            let mood_index = Object.values(moods).indexOf(mood_max)
            let mood = Object.keys(moods)[mood_index]
            this.setState({ moods: moods, mood: mood, samples: samples, mood_value: mood_max})
        });
    }

    render() {
        let texts = 'loading mood...'
        let moodmap = 'loading mood...'
        let sent_map  = {POSITIVE: '🙂', NEGATIVE: '☹️', NEUTRAL: '😐' }
        if(this.state.data.Items){
            var sorted_items = this.state.data.Items.sort(function (a, b) {
            	return b.timestamp_ms - a.timestamp_ms;
            });
            texts = sorted_items.map((tweet) => {
                let sentscore = tweet.sentimentScore[titleize(tweet.sentiment)]
                return <Tweet key={tweet.id_str} created_at={tweet.timestamp_ms} expires_at={tweet.expires} id_str={tweet.id_str} text={tweet.text} sentiment={sent_map[tweet.sentiment]} sentscore={sentscore}/>
                }
            );
            
            if(this.state.moods){
                moodmap = Object.keys(this.state.moods).map((e) => { return <li key={e}> {this.state.moods[e]} tweets categorized {e}</li> })
            }
            
        }
        return <div className="content">
            <div className="box">
                <h1>Trump is <span className="tooltip" data-tooltip={this.state.mood_value} style={{ 'fontSize': "40px" }}>{this.state.mood} </span> over his last {this.state.samples} tweets</h1>
                <ul>
                    {moodmap}
                </ul>
            </div>
           <ul>
               {texts}
           </ul>
        </div>
    }
}


export default Sent;